import $ from 'jquery'
import Offcanvas from "bootstrap/js/src/offcanvas";

export default class loadInCanvas {
  constructor (element) {
    this.$element = $(element)
    this.canvasId = this.$element.data('canvasId')
    this.canvasUrl = this.$element.data('canvasUrl')

    this.$canvas = $(`#${this.canvasId}`)
    this.$canvasClose = this.$canvas.find('.btn-close')
    this.offcanvas = new Offcanvas(this.$canvas.get(0), {})


    this.$canvasBody = this.$canvas.find('.offcanvas-body .dynamicContent')
    this.$loadingSpinner = this.$canvas.find('.offcanvas-body .loadingSpinner')

    this.$element.on('click', this.handleOpenClick.bind(this))
    this.$canvasClose.on('click', this.handleCloseClick.bind(this))
  }

  handleCloseClick(event) {
    this.offcanvas.hide()
  }

  handleOpenClick (event) {
    this.$loadingSpinner.addClass('active')

    this.offcanvas.show()
    this.$canvasBody.hide()
    this.$canvasBody.load(this.canvasUrl, (responseTxt, statusTxt, xhr) => {
      this.$canvasBody.show()
      this.$loadingSpinner.removeClass('active')
    });
  }


}
