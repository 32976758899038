import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'

export default class oneClickButtonBlock {
  constructor (element) {
    this.$element = $(element)

    // css classes, die die während geblockt ist angezeigt werden soll
    this.iconBlockClasses = 'fa-solid fa-circle-notch fa-spin';

    // timeout in sekunden, nachdem der button wieder freigegeben werden kann
    this.timeoutSeconds = 30;

    this.$button = this.$element.eq(0)
    const tagName = this.$button.prop("tagName").toLowerCase()
    switch(tagName) {
      case 'a':
      case 'button':
        break;
      default:
        this.$button = this.$element.find('a, button').eq(0)
        break;
    }

    this.$icon = this.$button.find('i');

    // kein icon da? lass uns ein leeres machen und injecten
    if (this.$icon.length < 1) {
      this.$icon = $('<i>')
      this.$button.prepend(this.$icon)
    }

    // die original classes merken (fürs wiederherstellen)
    this.originalIconClasses = this.$icon.attr('class') || '';


    this.timeout = null


    this.$button.on('click.oneClickButtonBlock', this.handleButtonClick.bind(this))
  }

  destroy() {
    this.$button.off('click.oneClickButtonBlock')
  }

  handleButtonClick(event) {
    this.timeout = window.setTimeout(this.handleTimeout.bind(this), this.timeoutSeconds *1000)

    this.$button.addClass('disabled')
    // this.$button.prop('disabled', true)

    // statt mit .addClass setzen wir das komplette class attribut - um alle css klassen aus dem icon zu entfernen
    this.$icon.attr('class', this.iconBlockClasses)
  }

  handleTimeout() {
    this.$button.removeClass('disabled')
    this.$button.prop('disabled', false)
    this.$icon.attr('class', this.originalIconClasses)
  }

}
