import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'

export default class adminMultiJob {
  constructor (element) {
    this.$element = $(element)
    this.$modal = this.$element

    this.jobData = this.$element.data('jobData')
    this.jobsTotal = this.jobData.length;
    this.errorMessages = [];

    this.approveUrl = this.$element.data('approveUrl')
    this.finishUrl = this.$element.data('finishUrl')

    // unclosable modal!
    this.modal = new Modal(this.$modal.get(0), {backdrop: 'static', keyboard: false})
    this.modal.show();

    this.$progressBarOldItems = this.$modal.find('.progress-bar.oldItems')
    this.$progressBarCurrent = this.$modal.find('.progress-bar.currentItem')
    this.$jobAllItemCount = this.$modal.find('.jobAllItemCount')
    this.$jobPatient = this.$modal.find('.jobPatient')
    this.$jobOrderNr = this.$modal.find('.jobOrderNr')
    this.$jobCurrenttemNumber = this.$modal.find('.jobCurrenttemNumber')

    this.$errorMessageContainer = this.$modal.find('.errorMessages')
    this.$errorMessageContainer.hide();
    this.$errorMessages = this.$errorMessageContainer.find('.messages')

    this.sendNextJob()
  }

  hasErrors() {
    return this.errorMessages.length > 0;
  }

  showErrors() {
    if (this.hasErrors()) {
      this.$errorMessages.text(this.errorMessages.join(', '))
      this.$errorMessageContainer.show()
      this.$progressBarCurrent.addClass('bg-danger')
    } else {
      this.$errorMessageContainer.hide()
    }
  }

  endJob() {
    console.log('endJob')
    this.$progressBarOldItems.css('width', `100%`)
    if (this.hasErrors()) {
      this.showErrors()
      return
    }
    window.setTimeout(() => {
      window.location.replace(this.finishUrl)
    }, 1000)
  }

  sendNextJob() {

    if (this.hasErrors()) {
      this.showErrors()
      return
    }

    this.$progressBarCurrent.css('width', `0%`)

    const nextItem = this.jobData.shift()
    if (!nextItem) {
      this.endJob()
      return
    }

    const currentJobNr = this.jobsTotal - this.jobData.length;
    const percent = Math.round(100/this.jobsTotal * (currentJobNr-1))
    const itemPercent = Math.round(100/this.jobsTotal)
    this.$progressBarOldItems.css('width', `${percent}%`)



    this.$jobPatient.text(nextItem.patient)
    this.$jobOrderNr.text(nextItem.orderNr)
    this.$jobCurrenttemNumber.text(currentJobNr)
    this.$jobAllItemCount.text(this.jobsTotal)

    const ajaxSettings = {
      url: this.approveUrl,
      method: 'POST',
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(nextItem),
      headers: {}
    }

    this.$progressBarCurrent.css('width', `${itemPercent}%`)
    $.ajax(ajaxSettings)
      .done(this.handleRequestDone.bind(this))
      .fail(this.handleRequestFail.bind(this))
  }


  handleRequestDone (data, textStatus, jqXHR) {
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleRequestFail (jqXHR, textStatus, errorThrown) {
    console.log('FAILED', jqXHR, textStatus, errorThrown)
    this.errorCount++;

    let data = {}
    try {
      if (jqXHR.responseText) {
        data = JSON.parse(jqXHR.responseText)
      }
    } catch (e) {
      console.error(jqXHR.responseText)
    }
    if (data.error) {
      if (typeof data.error === 'string') {
        this.errorMessages.push(data.error)
      }
      else {
        if (typeof data.error === 'object') {
          if (data.error.hasOwnProperty('message')) {
            this.errorMessages.push(data.error.message)
          }
        }
      }

    }

    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleResponse (status, responseData, rawData) {
    this.sendNextJob()
  }

  destroy() {

  }

}
