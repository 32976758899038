import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'
import Behaviors from "../behaviors";
import formSubmit from "./formSubmit";
import tracking from "../tracking";

export default class setAffiliateCookie  {
  constructor (element) {
    this.$element = $(element)
    this.cookieData =this.$element.data('cookieData');

    tracking.onTrackingAllowed(this.trackData.bind(this))
  }

  trackData() {

    for (const cookieName in this.cookieData) {
      const cookieData = this.cookieData[cookieName];
      if (cookieData) {
        document.cookie = `${cookieName}=${cookieData.cookieValue}; path=/; max-age=${60 * 60 * 24 * cookieData.expiresInDays};`;
      }
    }

  }

}
