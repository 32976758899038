import $ from 'jquery'
import Modal from "bootstrap/js/src/modal";

export default class stripePaymentModal {
  constructor (element) {
    this.$startbutton = $(element)
    this.modalId = this.$startbutton.data('modalId')
    this.workflowUrl = this.$startbutton.data('workflowUrl')
    this.workflowAction = this.$startbutton.data('workflowAction')
    this.$modal = $(`#${this.modalId}`)


    this.$spinner = this.$modal.find('.loadingSpinner')
    this.$embedContainer = this.$modal.find('.embedContainer')

    this.modal = new Modal(this.$modal.get(0))
    this.$modalBody = this.$modal.find('.modal-body')

    this.paymentStarted = false;
    this.$startbutton.on('click', this.handleButtonClick.bind(this))
  }

  handleButtonClick(event) {
    this.startPayment()
  }

  startPayment() {
    if (this.paymentStarted) {
      this.modal.show()
      return
    }

    this.paymentStarted = true

    this.$spinner.show();
    this.$embedContainer.empty().hide();

    this.modal.show()

    // ajax - auf workflow action 'startInlinePayment'
    const ajaxSettings = {
      url: this.workflowUrl,
      method: 'POST',
      cache: false,
      dataType: 'html',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'workflowNavigation': this.workflowAction}),
      headers: {}
    }

    $.ajax(ajaxSettings)
      .done(this.handleRequestDone.bind(this))
      .fail(this.handleRequestFail.bind(this))
  }


  handleRequestDone (data, textStatus, jqXHR) {
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleRequestFail (jqXHR, textStatus, errorThrown) {
    console.log('FAILED', jqXHR, textStatus, errorThrown)
    this.handleResponse(jqXHR.status, textStatus, jqXHR.responseText)
  }

  handleResponse (status, responseData, rawData) {
    console.log('handleResponse', status, responseData, rawData)

    this.$embedContainer.html(responseData)
    this.$spinner.hide()
    this.$embedContainer.show()
    this.initStripe()

  }

  destroy() {

  }

  initStripe() {
    const $form = this.$embedContainer.find('form')
    const $dataDiv = this.$embedContainer.find('.payment-data')
    const publishableKey = $dataDiv.data('publishableKey')
    const clientSecret = $dataDiv.data('clientSecret')
    const paymentElementOptions = $dataDiv.data('paymentElementOptions')
    const actionUrl = $dataDiv.data('actionUrl')
    const billing = $dataDiv.data('billing')
    // requires, that the document includes  <script src="https://js.stripe.com/v3/"></script>
    // this NEEDS to be load from stripe servers
    const stripe = Stripe(publishableKey);

    let elements

    initialize();
    checkStatus();

    $form.get(0).addEventListener("submit", handleSubmit);

    // Fetches a payment intent and captures the client secret
    async function initialize() {
      elements = stripe.elements({
        clientSecret: clientSecret,
      });

      const paymentElement = elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
    }

    async function handleSubmit(e) {
      e.preventDefault();
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: actionUrl,
          payment_method_data: {
            billing_details: billing // note: this is defined via paymentController -> setDetails -> billing
          },
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message);
      } else {
        showMessage("An unexpected error occurred.");
      }

      setLoading(false);
    }

    // Fetches the payment intent status after payment submission
    async function checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          showMessage("Payment succeeded!");
          break;
        case "processing":
          showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          showMessage("Your payment was not successful, please try again.");
          break;
        default:
          showMessage("Something went wrong.");
          break;
      }
    }

    // ------- UI helpers -------

    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageContainer.textContent = "";
      }, 4000);
    }

    // Show a spinner on payment submission
    function setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }

  }




}
