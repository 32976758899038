import $ from 'jquery'
export default class pharmacyAvailabilitySelectSubmitOnChange {
  constructor (element) {
    this.$element = $(element)
    this.$field = this.$element.find('select')
    this.$form = this.$field.closest('form')
    this.$targetField = this.$form.find('input[name=pharmacyAvailability]')
    this.$field.on('change', this.handleSelectChange.bind(this))
  }

  handleSelectChange(event) {
    this.$targetField.val(this.$field.val())
    this.$form.trigger('submit')
  }

  destroy() {

  }
}
