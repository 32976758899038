import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'

export default class downloadProgress {
  constructor (element) {
    this.$element = $(element)
    this.downloadUri = this.$element.attr('href')
    this.$modal = $('#downloadProgressModal')
    this.$progressBarWaiting = this.$modal.find('.progress-bar.waiting')
    this.$progressBarDownload = this.$modal.find('.progress-bar.download')
    this.$downloadFileName = this.$modal.find('.currentDownloadFile')

    this.downloadFileName = this.$element.data('downloadFileName')
    this.$element.on('click', this.handleClick.bind(this))
    this.modal = new Modal(this.$modal.get(0))
  }

  handleClick (event) {
    this.$progressBarDownload.width(`0%`)
    this.$progressBarDownload.hide()
    this.$progressBarWaiting.width(`100%`)
    this.$progressBarWaiting.show()
    this.$downloadFileName.text(this.downloadFileName)
    this.modal.show()

    event.preventDefault()
    let blob;
    const xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('GET', this.downloadUri, true)
    xmlHTTP.responseType = 'arraybuffer'
    xmlHTTP.onload = function (e) {
      blob = new Blob([this.response])
    }
    xmlHTTP.onprogress = (pr) => {
      this.$progressBarWaiting.hide()
      this.$progressBarDownload.show()

      let percent = 0
      if (!pr.loaded) {
        percent = 0
      } else if (pr.lengthComputable) {
        percent = Math.round(pr.loaded / pr.total * 100)
      } else {
        const junkSize = 10000000
        const prLoadedJunk = pr.loaded % junkSize
        percent = Math.round(prLoadedJunk / junkSize * 100)
        console.log(percent, prLoadedJunk)
      }
      this.$progressBarDownload.width(`${percent}%`)
    }
    xmlHTTP.onloadend = (e) => {
      this.$progressBarDownload.width(`100%`)
      var tempEl = document.createElement('a')
      document.body.appendChild(tempEl)
      tempEl.style = 'display: none'
      const url = window.URL.createObjectURL(blob)
      tempEl.href = url
      tempEl.download = this.downloadFileName
      tempEl.click()
      window.URL.revokeObjectURL(url)

      this.modal.hide()
    }
    xmlHTTP.send()
  }
}
